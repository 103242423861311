import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/core/services/message.service';
declare var hbspt: any

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit, AfterViewInit {

  constructor( ) 
    {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    hbspt.forms.create ({
      portalId: "5573496",
      formId: "e97423b8-cc4b-4574-af86-bff0e19bc9e5",
      target: "#contactForm"
    });
  }
  scroll() {
    document.querySelector('#companies').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
