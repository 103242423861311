import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isFirstOpen = true;
  today = new Date();
  year = this.today.getFullYear();

  constructor() { }

  ngOnInit() {
  }
  onNavigateAser(){
    let link = `https://aserseguridad.co/`;
    window.open(link, "_blank");
  }
  onNavigateInter(){
    let link = `http://www.interlaborales.co/`;
    window.open(link, "_blank");
  }

}
