import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { BlogComponent } from './pages/blog/blog.component';
import { PoliticsComponent } from './pages/politics/politics.component';


const routes: Routes = [
  { path: '', component: HomeComponent  },
  { path: 'blog', component: BlogComponent  },
  { path: 'politica-privacidad', component: PoliticsComponent  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
