import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public title: Title,
    public meta: Meta
  ) { }

  ngOnInit() {
    //Meta
    this.title.setTitle('GEOS');
    this.meta.addTag({ name: 'description', content: 'Somos un holding colombiano, con foco estratégico en el sector de riesgos laborales y empresariales. Nuestro propósito es respaldar y representar a nuestros clientes en la gestión continua de sus riesgos, creando bienestar y desarrollo sostenible para las personas, las organizaciones y la sociedad. '});
    this.meta.addTag({ name: 'keywords', content: 'Riesgos Laborales, Seguridad y Salud en el Trabajo, SG-SST – SST, SGSST, Seguros ARL, Seguros Generales, Seguros Personales, Gestión de Riesgos, Empresas de SST, Consultoría en Riesgos.'});
  }  
}
