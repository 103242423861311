import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  onNavigateAser(){
    let link = `https://aserseguridad.co/`;
    window.open(link, "_blank");
  }
  onNavigateInter(){
    let link = `https://intersegurosla.co/`;
    window.open(link, "_blank");
  }
}
