import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  currentRoute: string;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      this.currentRoute = event.url
    }});
  }

  ngOnInit() {
  }
  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  } 
  scrollUs() {
	if(this.currentRoute == '/politica-privacidad'){
		this.router.navigate(['/']);
		setTimeout(() => {
			document.querySelector('#us').scrollIntoView({ behavior: 'smooth', block: 'center' });
		}, 1000);		
	} else {
		document.querySelector('#us').scrollIntoView({ behavior: 'smooth', block: 'center' });
	}    
  }  
}
