import { Component, OnInit } from '@angular/core';
import { AlliesService } from 'src/app/core/services/allies.service';

@Component({
  selector: 'app-allies',
  templateUrl: './allies.component.html',
  styleUrls: ['./allies.component.scss']
})
export class AlliesComponent implements OnInit {

	listAllies = [];
	public tipoSel = 'todos'
	public offers = [];
	public offersFil = [];
	public companys: any;
	public numOffers = 0;	
	public titles = [
		{"tipo" : "todos", "title" : "Estas son las ofertas disponibles para ti"},	
		{"tipo" : "CREDITO", "title" : "<b>Ofertas de crédito</b> a tu medida"},	
		{"tipo" : "TC", "title" : "<b>Tarjetas de crédito</b> a tu medida"},	
		{"tipo" : "INVERSION", "title" : "<b>Inversión de ahorro</b> a tu medida"},	
		{"tipo" : "DEUDA", "title" : "<b>Reparadores de deuda</b> a tu medida"},	
		{"tipo" : "PI", "title" : "<b>Proyectos inmobiliarios</b> a tu medida"},	
	]
	public title = this.titles[0].title;


  constructor(
	  public alliesService: AlliesService
  ) { }

  ngOnInit() {
	this.alliesService.getItems().subscribe( result => {
		this.listAllies = result;
		this.offersFil = this.listAllies;
	});
  }

  filterTypeOffer(filtro, ele) {
    if (!ele.target.classList.contains("active")) {
		for (let index = 0; index < ele.target.parentElement.children.length; index++) {
			const element = ele.target.parentElement.children[index];
			element.classList.remove("active");
		}
      	ele.target.classList.add("active");
    }

    this.tipoSel = filtro;
    if (filtro == "Todos") {
      	this.offersFil = this.listAllies;
		return this.offersFil
    } else {
      	this.offersFil = this.listAllies.filter((ele) => ele.title == filtro);
		return this.offersFil
    }
  }

}
