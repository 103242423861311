import { Component, HostListener, OnInit } from '@angular/core';
//import { $ } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-politics',
  templateUrl: './politics.component.html',
  styleUrls: ['./politics.component.scss']
})
export class PoliticsComponent implements OnInit {

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  onScroll(event){
    if(window.pageYOffset !== 0){
      $(".nav-left-politics").css("top", "10%");
    } else {
      $(".nav-left-politics").css("top", "16%");
    }
  }

	ngOnInit() {
		window.scrollTo(0,0);
	}

	scroll(el: HTMLElement) {
		//el.scrollIntoView();
		el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
	}
	scrollMobile(value: string){    
		var element = $("#cap"+value)
		var pos = element.offset().top;
		switch(value) {
		case "1":
			$('body, html').animate({scrollTop: pos});
			break;
		case "2":
			$('body, html').animate({scrollTop: pos});
			break;
		case "3":
			$('body, html').animate({scrollTop: pos});
			break;
		case "4":
			$('body, html').animate({scrollTop: pos});
			break;
		case "5":
			$('body, html').animate({scrollTop: pos});
			break;
		case "6":
			$('body, html').animate({scrollTop: pos});
			break;
		}
	}

}
