import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  onNavigateAser(){
    let link = `https://aserseguridad.co/articulos`;
    window.open(link, "_blank");
  }
  onNavigateInter(){
    let link = `https://intersegurosla.co/blog`;
    window.open(link, "_blank");
  }
}
