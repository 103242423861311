import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { IntroComponent } from './pages/intro/intro.component';
import { UsComponent } from './pages/us/us.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { HomeComponent } from './pages/home/home.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//NGX BOOSTRAP
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

//MASCARA CELULAR
import { TextMaskModule } from 'angular2-text-mask';
import { MessageService } from './core/services/message.service';
import { VenuesComponent } from './pages/venues/venues.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { PoliticsComponent } from './pages/politics/politics.component';
import { AlliesComponent } from './pages/allies/allies.component';
import { BlogComponent } from './pages/blog/blog.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IntroComponent,
    UsComponent,
    CompaniesComponent,
    HomeComponent,
    VenuesComponent,
    TeamsComponent,
    PoliticsComponent,
    AlliesComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    // BsDatepickerModule.forRoot(),
    TextMaskModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonsModule.forRoot(),
    HttpClientModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
